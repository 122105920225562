<template>
  <ChangePasswordWrapper>
    <sdCards>
      <template v-slot:title>
        <div class="setting-card-title">
          <sdHeading as="h4">{{ i18n.t('profileView.globalSettings') }}</sdHeading>
          <span>{{ i18n.t('profileView.globalSubtitle') }}</span>
        </div>
      </template>
      <div class="notification-box-single" v-if="formState">
        <BasicFormWrapper>
          <a-form class="notification-body">
            <a-form-item :label="i18n.t('global.choooseLanguage')">
              <a-select v-model:value="formState.locale" style="width: 100%" ref="select" :options="languages">
              </a-select>
            </a-form-item>
            <a-form-item :label="i18n.t('global.choooseUTC')">
              <a-select v-model:value="formState.time_zone" style="width: 100%" ref="select" :options="zones">
              </a-select>
            </a-form-item>
          </a-form>
        </BasicFormWrapper>
      </div>
      <div class="notification-actions" style="margin-top: 20px">
        <sdButton size="default" @click="handleFinish" outlined type="success" :disabled="isLoading">
          {{ i18n.t('actions.update') }} {{ i18n.t('profileView.globalSettings') }}
        </sdButton>
      </div>
    </sdCards>
  </ChangePasswordWrapper>
</template>
<script>
/* eslint-disable */

import { ChangePasswordWrapper } from './style';
import { BasicFormWrapper } from '../../../styled';
import { defineComponent, ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { message } from 'ant-design-vue';
import { zones, languages } from '@/config/helpers/JSONdata.js';
import QrcodeVue from 'qrcode.vue';
import { getItem, setItem, setCookie } from '@/utility/localStorageControl';



const Global = defineComponent({
  name: 'Global',
  components: { ChangePasswordWrapper, QrcodeVue, BasicFormWrapper },
  setup() {
    const { state, dispatch } = useStore();
    const i18n = useI18n();

    const formState = computed(() => state.profile.userSettings);
    const isLoading = computed(() => state.profile.isLoading);

    const handleFinish = () => {
      i18n.locale.value = formState.value.locale;
      const fromLocal = getItem('user');
			fromLocal.settings = formState.value;
      
			setItem('user', JSON.stringify(fromLocal));
      setCookie({ name: 'language', value: formState.value.locale });

      dispatch('axiosChangeSettings', formState.value).then(() => {
        if (state.profile.changeSuccess == 'OK') {
          message.success(i18n.t('messages.settingsChanged'));
        } else {
          message.warning(i18n.t('messages.settingsNotChanged'));
        }
      });
    };
    onMounted(() => {
      dispatch('axiosGetSettings');
    });
    return {
      formState,
      isLoading,
      languages,
      zones,
      i18n,
      handleFinish,
    };
  },
});

export default Global;
</script>
